<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-form-item label="部门">
                  <el-cascader :options="departmentData" :props="{expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
                </el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search" clearable> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="departmentName" label="部门名称"></el-table-column>
          <el-table-column prop="departmentNum" label="当前部门人数" align="center"> </el-table-column>
          <el-table-column prop="departmentPrice" label="当前部门收入（元）" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.departmentPrice.toFixed(2) }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="totalDepartmentNum" label="部门总人数" align="center"></el-table-column>
          <el-table-column prop="totalDepartmentPrice" label="部门总收入（元）" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.totalDepartmentPrice.toFixed(2) }}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient.js";
import { Department } from "@/components/HospitalDomain/Department.js";
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var department = new Department(this.TokenClient, this.Services.Authorization);
    return {
      OutpatientDomain: outpatient,
      Department: department,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      departmentData: [],
      searchForm: {
        keyWord: "",
        departmentId: 0,
        datepicker: "",
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      departmentUserNumList: {},
    };
  },
  mounted() {
    this.LoadDepartment();
  },
  methods: {
    getDepartmentUserNum() {
      var _this = this;
      return new Promise((resolve, reject) => {
        _this.Department.DepartmentUserNum(
          0,
          function (data) {
            resolve(data.data);
          },
          function (err) {
            resolve(false);
          }
        );
      });
    },
    async LoadDepartment() {
      let organizationId = JSON.parse(sessionStorage.getItem("userInfo")).organizationId;
      var _this = this;
      _this.Department.GetDepartment(
        organizationId,
        async function (data) {
          _this.departmentData = data.data;
          _this.departmentUserNumList = await _this.getDepartmentUserNum();
          if (_this.departmentUserNumList) {
            _this.getList();
          }
        },
        function (err) {
          _this.departmentData = [];
        }
      );
    },
    handleOption(e) {
      this.searchForm.departmentId = e[e.length - 1];
      this.pageIndex = 1;
      this.getList();
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    },
    getList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      let map = {
        departmentList: this.departmentData,
        departmentId: item.departmentId ? item.departmentId : 0,
        startTime: item.startTime,
        endTime: item.endTime,
        pageIndex: this.pageIndex,
      };
      _this.OutpatientDomain.MZDepartmentPerformanceInfo(
        map,
        function (data) {
          _this.tableData = data.data.results;
          _this.tableData.forEach((item) => {
            _this.departmentUserNumList.forEach((i) => {
              if (i.departmentId == item.departmentId) {
                item.departmentNum = i.departmentNum;
                item.totalDepartmentNum = i.totalDepartmentNum;
              }
            });
          });
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    Search(event) {
      this.pageIndex = 1;
      this.getList();
    },
  },
};
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
